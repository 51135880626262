import React from 'react'
import { Link } from 'react-router-dom'
import { useScrollToTop } from './UserScrollTop'

const ThankYou = () => {
    useScrollToTop()
    return (
        <section className='gap'>
        <div className="thank-you-container">
            <h1>Thank You!</h1>
            <p>Your submission was successful.</p>
           <Link to="/" className="back-to-home mx-auto">Back to Home</Link>
        </div>
        </section>
    )
}

export default ThankYou