import React from 'react'
import { AiOutlineInstagram, AiOutlineTwitter } from "react-icons/ai";
import { BiLogoLinkedin, BiLogoFacebook } from "react-icons/bi";
import './Footer.css'
import { Link } from 'react-router-dom';
import { useProductContext } from '../context/CategoryContext';

const Footer = () => {
    const { productsCategory, fetchProductsByCategory, updateSelectedCategory } = useProductContext();

    const handleClick = async (category) => {
        await fetchProductsByCategory(category.category_url);
        updateSelectedCategory(category)
    }
    return (

        <footer>
            <div className="bg-color">
                <div className="container">
                    <div className="row gx-0">
                        <div className="col-lg-4 h-auto">
                            <div className=''>
                                <img src="/Variant surfaces logo white.png" alt="" className='footer-logo' />
                                <address>
                                    2200 Alberta Dr STE 100, Dallas, TX 75229, United States.
                                </address>
                                <a href='tel:+1 2145597374'>+1 214-559-7374</a>
                                <span>|</span>
                                <a href='mailto:info@variant.co'>info@variant.co</a>
                            </div>
                        </div>
                        <div className="col-lg-2 col-6 h-auto pages">
                            <div className="">
                                <h4>Resources</h4>
                                <ul className='mt-4'>
                                    <li><Link to={'/manuals'}>Manuals</Link></li>
                                    <li><Link to={'/faq'}>FAQ</Link></li>
                                    <li><Link to={'/warranty-registration'}>Warranty</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-6 pages-2 h-auto">
                            <div className="my-auto align-items-center ">
                                <h4>Products</h4>
                                <ul className='mt-4'>
                                    {/* <li>
                                    <span></span>
                                </li> */}
                                    {productsCategory.map((category) => (
                                        <li key={category.id}>
                                            <Link to={`/products/${category.category.replace(/\s+/g, '-')}`} onClick={() => handleClick(category)}>
                                                {category.category}
                                                {category.category === "Variant Quartz" && <sup className="registeredTrademark" style={{ "left": "0" }}>®</sup>}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-6 pages-2 h-auto">
                            <h4>Inspiration</h4>
                            <ul className='mt-4'>
                                <li><Link to={'/blogs'}>Blog</Link></li>
                                <li><Link to={'/design-gallery'}>Design Gallery</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-6 pages-2 h-auto">
                            <Link to={'/about-us'}><h4>About Us</h4></Link>
                            <Link to={'/contact-us'}><h4 className='mt-3' style={{ "textTransform": "capitalize" }}>CONTACT US</h4></Link>
                        </div>
                        <div className="col-12">
                            <div className="social-icons ms-3 text-center">
                                <a href="https://www.instagram.com/variantsurfaces/" target="_blank"><AiOutlineInstagram className='icon' /></a>
                                <a href="https://twitter.com/surfaces76660" target='_blank'><AiOutlineTwitter className='icon' /></a>
                                {/* <a href="https://twitter.com/variantsurfaces?s=21&t=f2N_G4fRtEPt9qJnMEmXew" target='_blank'><BiLogoLinkedin className='icon' /></a> */}
                                <a href="https://www.facebook.com/variantdfw/" target='_blank'><BiLogoFacebook className='icon' /></a>
                            </div>
                            <div className='privacy-para my-4'>
                                <span>© Variant Surfaces 2024</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer