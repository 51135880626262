import React from 'react'
import { Link } from 'react-router-dom'
import { useScrollToTop } from './UserScrollTop'

const NotFound = () => {
    useScrollToTop()
    return (
        <>
            <div id="page-not-found">
                <h1>Oops!</h1>
                <p >Page Not Found</p>
                <Link to="/"><button>Go To Home</button></Link>
            </div>
        </>
    )
}

export default NotFound