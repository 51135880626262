import React from 'react'

const Banner = (props) => {
  return (
    <div className='banner-section'>
        <img src={props.url} alt="banner-img" />
        <div className='banner-text'>
            <h1>{props.name}</h1>
        </div>
    </div>
  )
}

export default Banner