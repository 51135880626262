import React, { useEffect, useState } from "react"
import AOS from "aos";
import Loader from "./Loader";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useCategory, useProductContext } from "../context/CategoryContext";
import { useScrollToTop } from "./UserScrollTop";

const Products = () => {
    const [screenLoading, setScreenLoading] = useState(false);
    // const [tileCategories, setTileCategories] = useState([]);
    const { fetchProductsByCategory, productsCategory, updateSelectedCategory } = useProductContext([]);
    const navigate = useNavigate();


    useEffect(() => {
        AOS.init({ duration: 1500 });
        AOS.refresh();
        setScreenLoading(false);
        setTimeout(() => {
            setScreenLoading(false);
        }, 3000);

    }, []);



    const handleClick = async (category) => {
        await fetchProductsByCategory(category.category_url);
        updateSelectedCategory(category)
    }

    useScrollToTop();
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Countertops For Residential And Commercial Spaces- Variant Surfaces</title>
                <link rel="canonical" href="https://variant.co/products" />
                <meta name="description" content="Explore the vast range of countertop surfaces at Variant. Our extensive collection ranges from natural stone, and quartz, to porcelain countertops available in various colors, designs, textures, and finishes for your residential and commercial spaces." />
            </Helmet>

            <>
                {screenLoading ? (
                    <Loader />
                ) :
                    <>
                        <div className="product-banner">
                            <div className="row gx-0">
                                <div className="col-xl-7">
                                    <img src="assests/images/CALACATTA ARABASCATO 002.webp" alt="" />
                                </div>
                                <div className="col-xl-5">
                                    <div className="product-banner-text">
                                        <h1>Products</h1>
                                        <p>
                                            Our mission at Variant Surfaces is to provide our customers with the highest quality engineered and natural stones at the most competitive prices. We take pride in offering an extensive collection that caters to a diverse range of tastes and preferences, ensuring every customer finds the perfect solution for their needs. Explore our products below.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <section className="products-section-3 py-5 ">
                            <div className="container">
                                <h3 className="text-center">Variant Collection</h3>
                                <p className="text-center">
                                    Explore unparalleled quality and diverse elegance across our product lines.
                                </p>
                                <div className="row">
                                    <div className="row mt-md-5 mt-3 gx-3 justify-content-center">
                                        {productsCategory &&
                                            productsCategory.map((category) => {
                                                let overlayText = "";
                                                if (category.category === "Variant Quartz") {
                                                    overlayText = "Explore our vast selection of curated quartz designs to meet anyone’s needs";
                                                } else if (category.category === "Variant Porcelain™") {
                                                    overlayText = " Explore our meticulously crafted porcelain designs that are universally stunning";
                                                } else if (category.category === "Natural Stone") {
                                                    overlayText = "Explore our quartzite, granite, marble, dolomite, soapstone, and sodalite slabs";
                                                }
                                                return (
                                                    <div
                                                        className="col-lg-4 col-md-4  mb-md-0 mb-3"
                                                    // data-aos="fade-up"
                                                    >
                                                        <h4>{category.category}
                                                            {category.category === "Variant Quartz" && <sup className="registeredTrademark" style={{ "left": "0" }}>®</sup>}
                                                        </h4>
                                                        <Link
                                                            to={`/products/${category.category.replace(
                                                                /\s+/g,
                                                                "-"
                                                            )}`}
                                                            onClick={() => handleClick(category)}
                                                        >
                                                            <div
                                                                className="image-wrapper"
                                                            >
                                                                <img
                                                                    src={category.image}
                                                                    alt={category.category}
                                                                    className="image-1"
                                                                />
                                                                <div className="overlay">
                                                                    <p>{overlayText}</p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        <Link
                                                            to={`/products/${category.category.replace(
                                                                /\s+/g,
                                                                "-"
                                                            )}`}
                                                            onClick={() => handleClick(category)}
                                                        >
                                                            <button className="product-btn">
                                                                View Product
                                                            </button>
                                                        </Link>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* <Cta_Section /> */}
                        <section className='products-cta-section'>
                            <div className="bg-image">
                                <div className="row h-100 d-flex justify-content-center align-items-center">
                                    <div className="col-10 d-flex justify-content-center text-center">
                                        <div className='text-wrapper' data-aos="zoom-in">
                                            <h3>Need Help Finding the Right Product?</h3>
                                            {/* <p>
                                                Explore our products by category, color, finish, and thickness
                                            </p> */}
                                            <div className="mt-3">
                                                <Link to={"/contact-us"}><button>CONTACT US</button></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                }
            </>
        </>
    )
}

export default Products