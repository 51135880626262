import React, { useEffect, useState } from "react";
import AOS from "aos";
import { Helmet } from "react-helmet";
import Loader from "./Loader";
import { Link } from "react-router-dom";
import { useScrollToTop } from "./UserScrollTop";
import { useProductContext } from "../context/CategoryContext";

const About = () => {
  const [screenLoading, setScreenLoading] = useState(false);
  const { productsCategory, updateSelectedCategory, fetchProductsByCategory } =
    useProductContext();
  useEffect(() => {
    AOS.init({ duration: 1500 });
    AOS.refresh();
    setScreenLoading(false);
    setTimeout(() => {
      setScreenLoading(false);
    }, 3000);
  }, []);
  const handleClick = async (category) => {
    await fetchProductsByCategory(category.category_url);
    updateSelectedCategory(category);
  };
  useScrollToTop();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Our Company | Variant Surfaces </title>
        <link rel="canonical" href="https://variant.co/about-us" />
        <meta
          name="description"
          content="Variant Surfaces is owned and operated by experts in the industry. "
        />
        <meta
          name="keywords"
          content="Countertop distributors,Countertop solutions,Countertop distributors in Dallas,Kitchen Countertops,natural stones,quartz, and porcelain countertops,
"
        />
      </Helmet>
      <>
        {screenLoading ? (
          <Loader />
        ) : (
          <>
            <div className="about-banner-section">
              <img src="assests/images/2021-01-10.jpg" alt="banner-img" />
              <div className="about-banner-text">
                <h1>QUALITY SET IN STONE</h1>
                <p>
                  Variant Surfaces provides a wide range of globally sourced
                  natural stone, quartz, porcelain surfaces, and more. Visit our
                  showroom to upgrade your kitchen, bathroom, and other areas of
                  your home.
                </p>
                {/* <Link to={"/contact-us"}><button>CONTACT US</button></Link> */}
              </div>
            </div>
            <div className=" pt-5">
              <div className="container">
                <p>
                  Variant Surfaces is a family owned and operated stone
                  distributor dedicated to providing consumers with the
                  highest-quality surfaces at the best possible prices. Since
                  2010, our leadership team has been a prominent fixture in the
                  industry, forming strong relationships with the world's top
                  stone suppliers, which allows us to curate a selection of the
                  world's best surfaces. Our stunning collection of natural
                  stones - including quartzite, granite, marble, dolomite,
                  soapstone, and sodalite - along with our exclusive Variant
                  Quartz and Variant Porcelain slabs will elevate any project to
                  the next level.
                </p>

                <p>
                  At Variant, we are committed to providing an exceptional
                  customer experience. Our well-organized and knowledgeable team
                  is ready to assist you in selecting the perfect slabs for your
                  projects. Once you've made your choice, we will streamline the
                  buying process, ensuring complete customer satisfaction and
                  quick delivery. We invite fabricators, designers, architects,
                  builders, and homeowners to visit our showroom, where our team
                  can guide you through the selection process and help you find
                  the perfect slabs for your project.
                </p>
              </div>
            </div>

            <section className="section-10 gap">
              <div className="">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="text-wrapper">
                        <h3>Personally Curate Your Environment</h3>
                        <p className="my-4">
                          Our showroom is open to everyone. Stop by and let us
                          help you find the perfect materials for your needs.
                        </p>
                        <Link to={"/products"}>
                          <button>EXPLORE</button>
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-6 mt-lg-0 mt-3">
                      <div className="d-flex">
                        <img
                          src="assests/images/Home-Icon-01-1.png"
                          alt=""
                          className="icon"
                        />
                        <p className="my-auto">
                          Our staff will help you select the perfect material.
                        </p>
                      </div>
                      <div className="d-flex">
                        <img
                          src="assests/images/Home-Icon-02-1.png"
                          alt=""
                          className="icon"
                        />
                        <p className="my-auto">
                          We will hold your selection for 2 weeks.
                        </p>
                      </div>
                      <div className="d-flex">
                        <img
                          src="assests/images/Home-Icon-03-1.png"
                          alt=""
                          className="icon"
                        />
                        <p className="my-auto">
                          After finalizing your selection with your fabricator,
                          they will work with you on installation.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="products-section-3 gap pt-5 pb-5">
              <div className="container">
                <h3 className="text-center">Variant Collection</h3>
                <p className="text-center">
                  Explore distinctive charms and contemporary marvels across our
                  product lines
                </p>
                <div className="row">
                  <div className="row mt-5 gx-3 justify-content-center">
                    {productsCategory &&
                      productsCategory.map((category) => {
                        let overlayText = "";
                        if (category.category === "Variant Quartz") {
                          overlayText =
                            "Explore our vast selection of curated quartz designs to meet anyone’s needs";
                        } else if (category.category === "Variant Porcelain™") {
                          overlayText =
                            "Explore our meticulously crafted porcelain designs that are universally stunning";
                        } else if (category.category === "Natural Stone") {
                          overlayText =
                            "Explore our quartzite, granite, marble, dolomite, soapstone, and sodalite slabs";
                        }
                        return (
                          <div
                            className="col-lg-4 col-md-4  mb-md-0 mb-3"
                            // data-aos="fade-up"
                          >
                            <h4>
                              {category.category}
                              {category.category === "Variant Quartz" && (
                                <sup
                                  className="registeredTrademark"
                                  style={{ left: "0" }}
                                >
                                  ®
                                </sup>
                              )}
                            </h4>
                            <Link
                              to={`/products/${category.category.replace(
                                /\s+/g,
                                "-"
                              )}`}
                              onClick={() => handleClick(category)}
                            >
                              <div className="image-wrapper">
                                <img
                                  src={category.image}
                                  alt={category.category}
                                  className="image-1"
                                />
                                <div className="overlay">
                                  <p>{overlayText}</p>
                                </div>
                              </div>
                            </Link>
                            <Link
                              to={`/products/${category.category.replace(
                                /\s+/g,
                                "-"
                              )}`}
                              onClick={() => handleClick(category)}
                            >
                              <button className="product-btn">
                                View Product
                              </button>
                            </Link>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </section>

            <section className="about-cta-section">
              <div className="bg-image">
                <div className="row h-100 d-flex justify-content-center align-items-center">
                  <div className="col-10 d-flex justify-content-center text-center">
                    <div className="text-wrapper" data-aos="zoom-in">
                      <h3>Interested in Our Products?</h3>
                      <div className="mt-3">
                        <Link to={"/contact-us"}>
                          <button>CONTACT US</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </>
    </>
  );
};

export default About;
