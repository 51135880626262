import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaRegClock } from "react-icons/fa";
import { IoHomeOutline } from "react-icons/io5";
import { IoMdCall } from "react-icons/io";
import { CiMail } from "react-icons/ci";
import Loader from './Loader';
import AOS from 'aos';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useScrollToTop } from './UserScrollTop';
const Contact = () => {
    const [screenLoading, setScreenLoading] = useState(false);
    const navigate = useNavigate();
    const [captchaCode, setCaptchaCode] = useState('');
    const [userInput, setUserInput] = useState('');

    const [formData, setFormData] = useState({
        from_company: "Variant",
        name: '',
        email: '',
        product: '',
        company: '',
        mobile: '',
        city: '',
        message: '',
        botcheck: '',
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    useEffect(() => {
        AOS.init({ duration: 1500 });
        AOS.refresh();
        setScreenLoading(false);
        setTimeout(() => {
            setScreenLoading(false);
        }, 3000);
        generateCaptcha();
    }, []);


    const generateCaptcha = () => {
        const chr1 = Math.ceil(Math.random() * 10) + '';
        const chr2 = Math.ceil(Math.random() * 10) + '';
        const chr3 = Math.ceil(Math.random() * 10) + '';

        const str = new Array(4)
            .join()
            .replace(/(.|$)/g, function () {
                return (
                    (Math.random() * 36) | 0
                ).toString(36)[Math.random() < 0.5 ? 'toString' : 'toUpperCase']();
            });

        const newCaptchaCode = str + chr1 + ' ' + chr2 + ' ' + chr3;
        setCaptchaCode(newCaptchaCode);
    };

    const removeSpaces = (string) => {
        return string.split(' ').join('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formattedCaptcha = removeSpaces(captchaCode);
        const formattedUserInput = removeSpaces(userInput);
        const { botcheck, ...otherFormData } = formData;

        if (formattedCaptcha === formattedUserInput) {
            if (botcheck == '') {
                try {
                    const response = await axios.post(
                        'https://astrasurfaces.cretxglobal.com/contact/',
                        otherFormData,
                        {
                            auth: {
                                username: `${process.env.REACT_APP_API_USERNAME}`,
                                password: `${process.env.REACT_APP_API_PASSWORD}`,
                            },
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        }
                    );
                    setFormData({
                        name: '',
                        email: '',
                        product: '',
                        company: '',
                        mobile: '',
                        city: '',
                        message: '',
                    });

                    navigate('/thank-you');
                } catch (error) {
                    console.error('Error submitting form:', error.message);
                    // Handle error or display an error message to the user
                }
            }
        }
        else {
            // Captcha validation failed
            alert('Please enter a proper Captcha.');
        }
        generateCaptcha();
    };

    useScrollToTop()
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact Us | Contact Us For Countertop Solutions In Dallas </title>
                <link rel="canonical" href="https://variant.co/contact-us" />
                <meta name="description" content="Walk-ins are welcome any time during business hours. Call us at (214) 559-7374 or email us at info@variant.co." />
            </Helmet>
            <>
                {screenLoading ? (
                    <Loader />
                ) :
                    <>
                        <div className="contact-page">
                            <section className='contact-banner'>
                                <img src="/Variant-Location-Map-01.jpg" alt="" />
                            </section>

                            <section className="section-2 gap">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6 mb-md-0 mb-3">
                                            <div className='company-card'>
                                                <div className="card-image">
                                                    <img src="2021-01-10.jpg" alt="" />
                                                </div>
                                                <div className="card-body">
                                                    <h4>Variant Surfaces</h4>
                                                    <div className='d-flex pb-3'>
                                                        <IoHomeOutline className='icon' />
                                                        <p>
                                                            2200 Alberta Dr STE 100, Dallas, TX 75229, United States.
                                                        </p>
                                                    </div>
                                                    <div className='d-flex pb-3'>
                                                        <FaRegClock className='icon' />
                                                        <p>
                                                            Monday - Friday: 8:00AM–5:00PM <br />
                                                            Saturday: 9:00AM–3:00PM
                                                        </p>
                                                    </div>
                                                    <div className='d-flex pb-3'>
                                                        <IoMdCall className='icon' />
                                                        <p>
                                                            +1 214-559-7374
                                                        </p>
                                                    </div>
                                                    <div className='d-flex pb-3'>
                                                        <CiMail className='icon' />
                                                        <p>
                                                           info@variant.co
                                                        </p>
                                                    </div>
                                                    <div className='text-center'>
                                                        <a href="https://www.google.com/maps/place/Variant+Surfaces/@32.9036718,-96.9064301,17z/data=!3m1!4b1!4m6!3m5!1s0x864c274a087a1725:0xa4b6c0e5cb0c3ccb!8m2!3d32.9036718!4d-96.9038552!16s%2Fg%2F11jp04f85w?entry=ttu" target='_blank'>
                                                            <button>VISIT US</button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-md-0 mb-3">
                                            <div className='company-card'>
                                                <div className="card-image">
                                                    <img src="banner-2.webp" alt="" />
                                                </div>
                                                <div className="card-body">
                                                    <h4>Astra Surfaces</h4>
                                                    <div className='d-flex pb-3'>
                                                        <IoHomeOutline className='icon' />
                                                        <p>
                                                            1580 Lower Rd Suite B, Linden, NJ 07036, United States
                                                        </p>
                                                    </div>
                                                    <div className='d-flex pb-3'>
                                                        <FaRegClock className='icon' />
                                                        <p>
                                                            Monday - Friday: 8:00AM–5:00PM <br />
                                                            Saturday: 9:00AM–3:00PM
                                                        </p>
                                                    </div>
                                                    <div className='d-flex pb-3'>
                                                        <IoMdCall className='icon' />
                                                        <p>
                                                            +1 908-988-9902
                                                        </p>
                                                    </div>
                                                    <div className='d-flex pb-3'>
                                                        <CiMail className='icon' />
                                                        <p>
                                                            info@astrasurfaces.com
                                                        </p>
                                                    </div>
                                                    <div className='text-center'>
                                                        <a href="https://www.google.com/maps?client=safari&sca_esv=561581270&rls=en&output=search&q=astra+surface+nj&source=lnms&entry=mc&sa=X&ved=2ahUKEwir2YrXzIaBAxWGp1YBHduHBeAQ0pQJegQIchAB" target='_blank'>
                                                            <button>VISIT US</button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </section>

                            <section className="section-3 gap mb-5">
                                <div className="map">
                                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3349.7918288430146!2d-96.90643012433203!3d32.90367177361088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c274a087a1725%3A0xa4b6c0e5cb0c3ccb!2sVariant%20Surfaces!5e0!3m2!1sen!2sin!4v1705489208727!5m2!1sen!2sin" width="100%" height="550" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}

                                    <div className="row justify-content-center">
                                        <div className="col-xl-8 col-md-10 col-11">
                                            <div className="form">
                                                <h1>Get In Touch</h1>
                                                <form action="" className="mt-5" onSubmit={handleSubmit}>
                                                    <div className="row">
                                                        <input name="botcheck" className="visually-hidden" tabindex="-1" autocomplete="off" onChange={handleChange} value={formData.botcheck} />
                                                        <div className="col-12">
                                                            <div className="form-outline">
                                                                <input type="text" className="form-control visually-hidden" value={formData.from_company} placeholder="" name='from_company' onChange={handleChange} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-outline">
                                                                <input type="text" className="form-control" value={formData.name} placeholder="Name" name='name' onChange={handleChange} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mt-md-0 mt-4">
                                                            <div className="form-outline">
                                                                <input type="tel" className="form-control" placeholder="Phone Number"
                                                                    pattern="[0-9]*"
                                                                    value={formData.mobile} name='mobile' onChange={handleChange} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mt-4">
                                                            <div className="form-outline">
                                                                <input type="text" className="form-control" placeholder="Company" value={formData.company} name='company' onChange={handleChange} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mt-4">
                                                            <div className="form-outline">
                                                                <input type="tel" className="form-control" placeholder="Product Name" value={formData.product} name='product' onChange={handleChange} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mt-4">
                                                            <div className="form-outline">
                                                                <input type="email" className="form-control" placeholder="Email" value={formData.email} name='email' onChange={handleChange} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 mt-4">
                                                            <div className="form-outline">
                                                                <input type="text" className="form-control" placeholder="City" value={formData.city} name='city' onChange={handleChange} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mt-4">
                                                            <div className="form-outline">
                                                                <textarea className="form-control" rows="5" placeholder="Message" value={formData.message} name='message' onChange={handleChange} required ></textarea>
                                                            </div>
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <input
                                                                type="text"
                                                                id="txtCaptcha"
                                                                style={{
                                                                    textAlign: 'center',
                                                                    border: 'none',
                                                                    fontWeight: 'bold',
                                                                    fontSize: '20px',
                                                                    fontFamily: 'Modern',
                                                                }}
                                                                readOnly
                                                                value={captchaCode}
                                                            />
                                                            <input
                                                                type="button"
                                                                id="btnrefresh"
                                                                value="Refresh"
                                                                onClick={generateCaptcha}

                                                            />
                                                        </div>
                                                        <div className="form-group mt-3">
                                                            <input
                                                                type="text"
                                                                placeholder="Enter Captcha"
                                                                className="form-control"
                                                                id="txtCompare"
                                                                value={userInput}
                                                                onChange={(e)=>setUserInput(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-lg-12 mt-4">
                                                            <button className="submit-btn">Submit</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </>
                }
            </>
        </>
    )
}

export default Contact